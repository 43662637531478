"use client"

import { alpha, createTheme, PaletteMode, ThemeOptions } from "@mui/material"

import { typography } from "./typography"

export const borderRadius = 8

// internal. augment palette to feed the colors in through the theme.
export const colors = {
  main: {
    main: "#4f3cf6",
    light: "#4f3cf6",
  },
  yellow: {
    main: "#FFC651",
    light: "#F5DDAC",
  },
  orange: {
    main: "#F39810",
    light: "#FBEFDF",
  },
  red: {
    main: "#DC402A",
    light: "#FFE9E5",
    bright: "#D9515B",
  },
  blue: {
    main: "#4F38F7",
    light: "#E6F5FC",
  },
  green: {
    main: "#14AE5C",
    light: "#E3FAED",
  },
  base: {
    white: "#FFFFFF",
    offWhite: "#F2F3F6",
    greyLight: "#DCDDE0",
    greyMid: "#96979A",
    greyMid2: "#545558",
    greyDark: "#424346",
    greyDark2: "#222326",
    blue: "#4200FF",
    black: "#06070A",
    almostBlack: "#16171A",
  },
  transparency: {
    white80: 0.8,
    white40: 0.4,
    white48: 0.48,
    white16: 0.16,
    black80: 0.8,
    black40: 0.4,
    black16: 0.16,
    black06: 0.06,
  },
}

export const breakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1600,
}

declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    accent: true
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    circle: true
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    bodyL: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    bodyL?: React.CSSProperties
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    bodyL: true
  }
}

// themeConfig builds a ThemeOptions for the given PalleteMode (either "light" or "dark")
const themeConfig = (mode: PaletteMode): ThemeOptions => {
  const light = mode === "light" // Makes mode checks more concise
  return {
    spacing: 10,
    shape: {
      borderRadius,
    },
    breakpoints: {
      values: breakpoints,
    },
    palette: {
      mode,
      primary: {
        main: light ? colors.base.black : colors.base.white,
        contrastText: light ? colors.base.white : colors.base.black,
      },
      secondary: {
        main: light ? colors.main.main : colors.main.light,
      },
      background: {
        default: light ? colors.base.white : colors.base.black,
        paper: light ? colors.base.white : colors.base.black,
      },
      success: {
        contrastText: colors.base.black,
        main: light ? colors.green.main : colors.green.light,
      },
      info: {
        contrastText: colors.base.black,
        main: light ? colors.main.main : colors.main.light,
      },
      error: {
        contrastText: colors.base.black,
        main: light ? colors.red.main : colors.red.light,
      },
      warning: {
        contrastText: colors.base.black,
        main: light ? colors.yellow.main : colors.yellow.light,
      },
      grey: {
        "50": colors.base.offWhite,
        "100": colors.base.greyLight,
        "200": colors.base.greyLight,
        "300": colors.base.greyLight,
        "400": colors.base.greyMid,
        "500": colors.base.greyMid2,
        "600": colors.base.greyMid,
        "700": colors.base.greyDark,
        "800": colors.base.greyDark2,
        "900": colors.base.black,
      },
      action: {
        active: alpha(
          light ? colors.base.black : colors.base.white,
          light ? colors.transparency.black40 : colors.transparency.white48
        ),
        hover: alpha(
          light ? colors.base.black : colors.base.white,
          light ? colors.transparency.black06 : colors.transparency.white16
        ),
        hoverOpacity: light ? colors.transparency.black16 : colors.transparency.white16,
        selected: alpha(
          light ? colors.base.black : colors.base.white,
          light ? colors.transparency.black16 : colors.transparency.white16
        ),
        selectedOpacity: light ? colors.transparency.black16 : colors.transparency.white16,
        disabled: alpha(
          light ? colors.base.black : colors.base.white,
          light ? colors.transparency.black40 : colors.transparency.white80
        ),
        disabledBackground: alpha(
          light ? colors.base.black : colors.base.white,
          light ? colors.transparency.black40 : colors.transparency.white40
        ),
        disabledOpacity: light ? colors.transparency.black40 : colors.transparency.white48,
        focus: alpha(
          light ? colors.base.black : colors.base.white,
          light ? colors.transparency.black16 : colors.transparency.white16
        ),
        focusOpacity: light ? colors.transparency.black16 : colors.transparency.white16,
        activatedOpacity: light ? colors.transparency.black16 : colors.transparency.white16,
      },
      divider: light ? colors.base.greyLight : colors.base.greyDark,
    },
    shadows: [
      "none",
      "0px 2px 5px 1px rgba(18, 18, 18, 0.1)",
      "0px 2px 5px 1px rgba(18, 18, 18, 0.1)",
      "0px 2px 5px 1px rgba(18, 18, 18, 0.1)",
      "0px 2px 5px 1px rgba(18, 18, 18, 0.1)",
      "0px 2px 5px 1px rgba(18, 18, 18, 0.1)",
      "0px 2px 5px 1px rgba(18, 18, 18, 0.1)",
      "0px 2px 5px 1px rgba(18, 18, 18, 0.1)",
      "0px 2px 5px 1px rgba(18, 18, 18, 0.1)",
      "0px 2px 5px 1px rgba(18, 18, 18, 0.1)",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
      "none",
    ],
    components: {
      MuiButtonBase: {
        defaultProps: {
          // Disable ripple globally
          disableRipple: true,
        },
      },
      MuiPaper: {
        variants: [
          {
            props: { variant: "accent" },
            style: {
              background: light ? colors.base.offWhite : colors.base.greyDark2,
            },
          },
        ],
        styleOverrides: {
          rounded: {
            borderRadius: "16px",
          },
        },
      },

      MuiButton: {
        variants: [
          {
            props: { variant: "circle" },
            style: {
              background: colors.base.white,
              borderRadius: "50%",
              border: `1px solid ${colors.base.greyLight}`,
              padding: "10px",
              minWidth: "0px",
              "&:hover": {
                background: colors.base.white,
                border: `1px solid ${colors.base.greyMid}`,
              },
            },
          },
        ],
        styleOverrides: {
          root: {
            textTransform: "none",
            paddingLeft: "12px",
            paddingRight: "12px",
            boxShadow: "none",
          },
          sizeLarge: {
            borderRadius: 8,
          },
          sizeMedium: {
            borderRadius: 8,
          },
          sizeSmall: {
            borderRadius: 6,
            minWidth: "auto",
          },
          fullWidth: {},
          outlinedSecondary: {
            border: mode === "dark" ? "rgba(255,255,255,0.5) solid 1px" : undefined,
          },
          textSecondary: {
            color: mode === "dark" ? "#FFF" : undefined,
          },
          outlinedPrimary: {
            borderColor: light ? colors.base.greyLight : colors.base.greyDark,
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          sizeLarge: {
            borderRadius: 12,
          },
          sizeMedium: {
            borderRadius: 8,
          },
          sizeSmall: {
            borderRadius: 6,
          },
          root: {
            ...(mode === "dark" ? { background: colors.base.black } : { background: colors.base.white }),
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            borderRadius,
            ...(mode === "dark" ? { color: colors.base.white } : { color: colors.base.black }),
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            borderRadius: "8px",
            padding: "10px 15px",
            color: mode === "light" ? colors.base.black : colors.base.white,
            backgroundColor: mode === "light" ? colors.base.white : colors.base.black,
            border: `1px solid ${mode === "light" ? colors.base.greyLight : colors.base.greyDark}`,
            boxShadow: "0px 4px 8px rgba(18, 18, 18, 0.08)",
            marginBottom: "3px !important",
            marginLeft: "-8px",
            maxWidth: "350px",
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            padding: 0,
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          primary: {
            fontSize: 16,
            lineHeight: "24px",
          },
          secondary: {
            fontSize: 14,
            color: colors.base.greyMid,
          },
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            fontSize: 14,
            textTransform: "uppercase",
            lineHeight: "2rem",
            opacity: 0.5,
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            borderRadius: "8px !important",
            border: `1px solid ${mode === "light" ? colors.base.greyLight : colors.base.greyDark}`,
          },
        },
      },
      MuiListItemSecondaryAction: {
        styleOverrides: {
          root: {
            fontSize: 16,
          },
        },
      },
      MuiList: {
        styleOverrides: {
          padding: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            height: "3px",
            borderRadius: "1.5px",
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {},
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: "16px 40px",
            borderBottom: `1px solid ${colors.base.greyLight}`,
            verticalAlign: "top",
            textWrap: "nowrap",
          },
          head: {
            color: colors.base.greyMid2,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minHeight: "auto",
            minWidth: "auto !important",
            textTransform: "none",
            color: "text.primary",
            opacity: 1,
            padding: "14px 2px",
            maxWidth: "100%",
            "&:hover": {
              backgroundColor: "transparent !important",
              color: "text.secondary",
            },
            //allows the indicator to appear over dividers
            overflow: "visible",
            //disables cursor until over the tab chip
            cursor: "default",
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          //overflow: visible allows the indicator to appear over dividers
          root: { height: "100%", overflow: "visible", padding: "0px 14px" },
          flexContainer: {
            height: "100%",
          },
          indicator: {
            display: "none",
          },
          vertical: {
            minWidth: "10em",
            "& .MuiTab-root": {
              alignItems: "flex-start",
            },
          },
          //enables border to be seen over divider
          scroller: { overflow: "visible !important" },
        },
      },
      MuiChip: {
        styleOverrides: {
          sizeSmall: {
            ...typography.subtitle1,
            opacity: 1,
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            "& input.MuiInputBase-input": {
              padding: "11.5px 14px",
              // height: "46px",
            },
            "& .MuiInputLabel-root": {
              top: "-4px",
            },
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          labelContainer: {
            color: "inherit",
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            ":before": {
              opacity: "1 !important",
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            //Select dropdown styling
            // "& .MuiSelect-select": {
            //   padding: "11.5px 14px",
            //   // height: "46px",
            // },
          },
        },
      },

      MuiSwitch: {
        styleOverrides: {
          root: {
            width: 40,
            height: 24,
            padding: 0,
            "& .MuiSwitch-switchBase": {
              padding: 0,
              margin: 2,
              transitionDuration: "300ms",
              "&.Mui-checked": {
                transform: "translateX(16px)",
                color: "#fff",
                "& + .MuiSwitch-track": {
                  backgroundColor: mode === "light" ? colors.main.main : colors.main.light,
                  opacity: 1,
                },
                "&.Mui-disabled + .MuiSwitch-track": {
                  opacity: 0.5,
                },
              },
              "&.Mui-focusVisible .MuiSwitch-thumb": {
                color: "#33cf4d",
                border: `6px solid ${colors.base.white}`,
              },
              "&.Mui-disabled .MuiSwitch-thumb": {
                color: mode === "light" ? colors.base.greyMid : colors.base.greyMid,
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: mode === "light" ? 0.7 : 0.3,
              },
            },
            "& .MuiSwitch-thumb": {
              boxSizing: "border-box",
              width: 20,
              height: 20,
            },
            "& .MuiSwitch-track": {
              border: "1px solid " + colors.base.greyMid + "77",
              borderRadius: 26 / 2,
              backgroundColor: mode === "light" ? colors.base.greyLight : colors.base.black,
              opacity: 1,
            },
          },
        },
      },
      MuiSkeleton: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            bodyL: "p",
          },
        },
      },
    },
    typography,
  }
}

export const darkTheme = createTheme(themeConfig("dark"))
export const lightTheme = createTheme(themeConfig("light"))
