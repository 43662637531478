"use client"

import { createTheme } from "@mui/material/styles"
import { TypographyOptions } from "@mui/material/styles/createTypography"

const { breakpoints } = createTheme()

export const typography: TypographyOptions = {
  fontWeightRegular: 400,
  fontSize: 16,
  htmlFontSize: 16,
  fontFamily: "Alliance, Helvetica Neue, Helvetica, Sans-serif",

  h1: {
    fontSize: 80,
    lineHeight: "88px",
    fontFamily: "Alliance",
    fontWeight: "600",
    [breakpoints.down("md")]: {
      fontSize: 48,
      lineHeight: "56px",
    },
    [breakpoints.down("sm")]: {
      fontSize: 32,
      lineHeight: "36px",
    },
  },
  h2: {
    fontSize: 48,
    lineHeight: "56px",
    fontWeight: "normal",
    fontFamily: "Alliance",

    [breakpoints.down("sm")]: {
      fontSize: 28,
      lineHeight: "36px",
    },
  },
  h3: {
    fontSize: 40,
    lineHeight: "48px",
    fontWeight: "normal",
    fontFamily: "Alliance",

    [breakpoints.down("sm")]: {
      fontSize: 24,
      lineHeight: "32px",
    },
  },
  h4: {
    fontSize: 32,
    lineHeight: "40px",
    fontWeight: "normal",
    fontFamily: "Alliance",
    [breakpoints.down("sm")]: {
      fontSize: 24,
      lineHeight: "32px",
    },
  },
  h5: {
    fontSize: 24,
    lineHeight: "32px",
    fontWeight: "normal",
    fontFamily: "Alliance",
    [breakpoints.down("sm")]: {
      fontSize: 18,
      lineHeight: "28px",
    },
  },
  h6: {
    fontSize: 20,
    lineHeight: "28px",
    fontFamily: "Alliance",
    fontWeight: "normal",
    [breakpoints.down("sm")]: {
      fontSize: 16,
      lineHeight: "24px",
    },
  },

  body1: { fontSize: 16, lineHeight: "24px" },
  body2: { fontSize: 14, lineHeight: "20px", fontWeight: "normal" },

  subtitle1: { fontSize: 12, lineHeight: "16px", fontWeight: 400 },
  subtitle2: {
    fontSize: 12,
    lineHeight: "16px",
    fontWeight: 400,
    opacity: 0.5,
  },
  button: { fontSize: 16, fontWeight: "normal" },
}
